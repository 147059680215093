import { Api } from '../api';
import {
  Notification,
  NewNotificationData,
  UpdateNotificationData,
} from '../types/Notifications';

export class NotificationsDataSource {
  async getByOperator(responsibleOperator: string, read: boolean) {
    try {
      const result = (await new Api().fetch(
        `/notifications/operator/${responsibleOperator}/${read}`
      )) as Array<Notification>;
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async read(id: number, operator_cognito_id: string, obs?: string) {
    const data = {
      operator_cognito_id: operator_cognito_id,
      obs: obs ? obs : '',
    };
    try {
      return await new Api().post(`/notifications/read/${id}`, {
        data,
      });
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async getBadgeCount(operator_id: string) {
    try {
      const response = (await new Api().fetch(
        `/notifications/badge/${operator_id}`
      )) as { count: number };
      return response.count;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async getAllNotifications() {
    try {
      const result = (await new Api().fetch(
        '/notifications'
      )) as Array<Notification>;
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async createNotification(data: NewNotificationData) {
    try {
      const result = await new Api().post('/notifications', data);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }

  async updateNotification(data: UpdateNotificationData) {
    try {
      const result = await new Api().put('/notifications', data);
      return result;
    } catch (e: any) {
      console.log(`Error: ${e.toString()}`);
      return null;
    }
  }
}
