import { Navigate, Outlet } from 'react-router-dom';
import { LoginService } from '../../services/auth';
import { usePortalStore } from '../../store';

export const PrivateRoute = () => {
  const loginService = LoginService;
  let userAlreadySetup = false;

  const isLogged = loginService.isLogged();
  const setCurrentUser = usePortalStore((state: any) => state.setCurrentUser);

  if (isLogged && !userAlreadySetup) {
    userAlreadySetup = true;
    const user = loginService.currentUser();
    setCurrentUser(user);
  }

  return isLogged ? <Outlet /> : <Navigate to="/login" />;
};
