import React from 'react';
import { ListItem, ListItemButton, Box } from '@mui/material';
import { Person, DescriptionOutlined } from '@mui/icons-material';
import { NotificationModal } from '../../../NotificationModal';
import { AlarmLamp } from '../../../orders/AlarmLamp';
import { Notification } from '../../../../types/Notifications';

type NotificationItemProps = {
  notification: Notification;
  handleCloseMenuList: () => void;
};

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  handleCloseMenuList,
}) => {
  const [showNotificationModal, setShowNotificationModal] =
    React.useState(false);

  const toggleShowNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  return (
    <>
      <ListItemButton
        sx={{ padding: '0px' }}
        onClick={toggleShowNotificationModal}
      >
        <ListItem disablePadding={true}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '5px',
            }}
          >
            <AlarmLamp alarm={notification.alarm}></AlarmLamp>
            <Box
              sx={{
                flexFlow: 'row',
                flex: 1,
                margin: 'auto auto auto 10px',
              }}
            >
              <Box
                sx={{
                  fontWeight: '700',
                  paddingLeft: '5px',
                }}
              >
                {notification.alarm.name}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Person htmlColor="black"></Person>
                <Box sx={{ padding: '0px 0px 0px 5px', width: '100%' }}>
                  {notification.patient} ({notification.patient_sap})
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <DescriptionOutlined htmlColor="black"></DescriptionOutlined>
                <Box sx={{ padding: '0px 0px 0px 5px', width: '100%' }}>
                  Pedido: {notification.request_number_temp}
                </Box>
              </Box>
            </Box>
          </Box>
        </ListItem>
      </ListItemButton>

      <NotificationModal
        open={showNotificationModal}
        handleClose={() => setShowNotificationModal(false)}
        handleCloseMenuList={handleCloseMenuList}
        notification={notification}
      />
    </>
  );
};
