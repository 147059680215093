import { Api } from '../api';
import { ComboBoxItems } from '../components/common/Combobox';
import { MultipleSelectItems } from '../components/common/MultipleSelect';
import { Order, OrderToUpdate, OrderGetAllParams } from '../types/Order';
import { Product } from '../types/Product';

type MultiSelectApi = {
  id: number;
  name: string;
};

export class OrdersDataSource {
  private path = '/orders';

  async getOrderById(id: number) {
    return (await new Api().fetch(`${this.path}/id/${id}`)) as Order;
  }

  async getDeliveryOperations() {
    const response = (await new Api().fetch(
      `${this.path}/delivery-operations`
    )) as MultiSelectApi[];

    return response.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];
  }

  async getServiceEvents() {
    const response = (await new Api().fetch(
      `${this.path}/service-events`
    )) as MultiSelectApi[];

    return response.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];
  }

  async getRetreatReasons() {
    const response = (await new Api().fetch(
      `${this.path}/retreat-reasons`
    )) as MultiSelectApi[];

    return response.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as MultipleSelectItems[];
  }

  async getStatus() {
    const response = (await new Api().fetch(
      `${this.path}/status`
    )) as MultiSelectApi[];

    return response.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as ComboBoxItems[];
  }

  async getStatusComplements() {
    const response = (await new Api().fetch(
      `${this.path}/status-complements`
    )) as MultiSelectApi[];

    return response.map((item) => ({
      label: item.name,
      value: item.id.toString(),
    })) as ComboBoxItems[];
  }

  async update(order: OrderToUpdate) {
    return await new Api().put(`${this.path}`, order);
  }

  async getAll(filters: OrderGetAllParams) {
    return await new Api().post(`${this.path}/get-all`, filters);
  }

  async getOrderProductDetails(
    request_number_temp: string
  ): Promise<Product[]> {
    return (await new Api().fetch(
      `${this.path}/details/${request_number_temp}`
    )) as Product[];
  }
}
