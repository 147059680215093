import { UserHelper } from './UserHelper';

export class JWTHelper {
  static getTokenFromStorage() {
    const currentUser = UserHelper.getCurrentUser();

    const token = localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_WEBCLIENT_ID}.${currentUser?.username}.idToken`
    );

    return token;
  }
}
