import { formatDate } from '../utils/utils';
import { getFullBallast } from './ballastService';
import { doPost } from './httpHelper';
import { getBranch, getPatientByCPF } from './patientsService';
import { queryProducts } from './productService';
import { getProductsByCodes } from './productService';
import { getLastSales } from './salesService';
import { OrderEnums } from '../enums/OrderEnums';
import { UserHelper } from '../helpers/UserHelper';
import moment from 'moment';

const CYLINDER_SEGMENT = 3;
const GASES_SEGMENT = '88';

export const fetchPatientsWithCylinders = async (
  filter,
  onlyWithCylinders,
  page,
  pageSize
) => {
  let ballast = await getFullBallast(
    filter,
    page,
    pageSize,
    onlyWithCylinders ? CYLINDER_SEGMENT : null
  );

  const patientsMap = {};
  ballast.data.forEach((p) => {
    if (p.cpf) {
      patientsMap[p.cpf] = 1;
    }
  });

  const patientsToFetch = (
    await Promise.all(
      Object.keys(patientsMap).map((x) => getPatientByCPF(null, x))
    )
  ).filter((x) => x !== undefined);

  patientsToFetch.forEach((p) => {
    if (p.cpf) {
      patientsMap[p.cpf] = p;
    }
  });

  // FIXME: This code is just a workaround to make Recharge work again!! BEGIN
  const mappedPatientsCPFs = Object.keys(patientsMap);
  const fetchedPatientsCPFs = patientsToFetch.map((x) => x.cpf);

  // Difference between mappedPatients and fetchedPatients
  const disabledPatientsCPFs = mappedPatientsCPFs.filter(function (obj) {
    return fetchedPatientsCPFs.indexOf(obj) == -1;
  });

  // Fixing patientsMap
  disabledPatientsCPFs.forEach((cpf) => {
    delete patientsMap[cpf];
  });

  // Fixing ballast
  ballast.data = ballast.data.filter(
    (x) => !disabledPatientsCPFs.includes(x.cpf)
  );
  // FIXME: This code is just a workaround to make Recharge work again!! END

  let lastSales = await Promise.all(
    Object.keys(patientsMap).map((cpf) =>
      fetchLatestCylindersUsedByPatient(cpf, GASES_SEGMENT)
    )
  );
  lastSales = lastSales.flat();
  lastSales.forEach((sales) => {
    patientsMap[sales.cpf].lastSales = sales.sales;
  });

  let prodIds = [];
  if (Array.isArray(lastSales)) {
    prodIds = lastSales
      .map((x) => x.sales)
      .flat()
      .map((x) => x.cod_prod);
    prodIds = new Set(prodIds);
  }

  const prods = await getProductsByCodes(Array.from(prodIds));

  const mappedProd = prods.reduce(
    (prod, prev) => (prev[prod.sap_code] = prod),
    {}
  );
  const patients = ballast.data.map((ballast) => {
    const cylinders =
      patientsMap[ballast.cpf]?.lastSales
        .map((prod) => {
          const ret = {
            type: '',
            product: prod.description
              ? prod.description
              : mappedProd[prod.cod_prod]
              ? mappedProd[prod.cod_prod].description
              : null,
            size: prod.size
              ? prod.size
              : mappedProd[prod.cod_prod]
              ? mappedProd[prod.cod_prod].size
              : '',
            id: prod.cod_prod,
          };
          if (ret.product === null) {
            return null;
          }
          return ret;
        })
        .filter((x) => x !== null) ?? [];

    if (cylinders.length === 0) {
      cylinders.push({
        type: '',
        product: 'Cilindro genérico',
        size: '',
        id: '80000017',
      });
    }

    return {
      id: ballast.receiving_code,
      name: ballast.receiving_name,
      cpf: ballast.cpf ?? '',
      cnpj: (patientsMap[ballast.cpf] ?? {}).cnpj ?? '',
      cep: (patientsMap[ballast.cpf] ?? {}).cep ?? '',
      city: (patientsMap[ballast.cpf] ?? {}).city ?? '',
      address: (patientsMap[ballast.cpf] ?? {}).address ?? '',
      company: (patientsMap[ballast.cpf] ?? {}).company ?? '',

      cylinders: cylinders,
      addressLine2: '',
    };
  });

  if (onlyWithCylinders) {
    return {
      rows: ballast.rows,
      data: patients.filter((x) => x.cylinders.length > 0),
    };
  }
  return {
    rows: ballast.rows,
    data: patients,
  };
};

export const fetchLatestCylindersUsedByPatient = async (cpf, subcategory) => {
  const sales = await getLastSales(cpf, 0, 5000);
  return {
    cpf: cpf,
    sales:
      subcategory !== null
        ? sales.data.filter((x) => x.product_subcategory_id === subcategory)
        : sales.data,
  };
  /**
     * "id": 143,
      "cnpj": "46392148002325",
      "cpf": "22865574890",
      "company_social_name": "COORDENADORIA REGIONAL DE SAUDE SUL",
      "patient_social_reason": "VICTORIA FELISDORO XAVIER",
      "cod_cli": "20020519",
      "cod_prod": "40000520",
      "description": "Cilindro O2 Medicinal - 6,2 m³",
      "size": "6.2",
      "dat_venda": "2023-03-19",
      "product_category_id": "2",
      "product_subcategory_id": "88",
      "product_segment_id": "6"
     */
};

export const getAllGasesProducts = async () => {
  const prods = await queryProducts(
    null,
    null,
    null,
    null,
    '88',
    null,
    null,
    null,
    null,
    0,
    10000
  );
  return prods.data;
};

export const saveRechargerOrder = async (
  currentUser,
  cnpj,
  patient,
  data,
  deliveryDate,
  techDetails,
  deliveryTurn
) => {
  const orderTempNumber = new Date().getTime().toString();
  const branch = await getBranch(patient.city);
  if (typeof branch === 'string' && branch.indexOf('Branch not found') >= 0) {
    return null;
  }
  if (branch.message && branch.message === 'Filial não encontrada') {
    return null;
  }
  const turns = [];
  Object.keys(deliveryTurn).forEach((turn) => {
    if (deliveryTurn[turn]) {
      turns.push(turn);
    }
  });
  const toRecharge = Object.values(data)
    .filter((x) => x.action === 'recarga' && x.quantity > 0)
    .map((x) => ({
      product_code: x.cylinder.id.toString(),
      product_unit_value: 0,
      quantity_volume: x.quantity > 0 ? x.quantity : 1,
    }));
  const toExchange = Object.values(data)
    .filter((x) => x.action === 'troca' && x.newCylinder !== undefined)
    .map((x) => ({
      product_code: x.newCylinder.sap_code,
      product_unit_value: 0,
      quantity_volume: x.quantity > 0 ? x.quantity : 1,
    }));
  const toCollect = Object.values(data)
    .filter((x) => x.action === 'troca' && x.newCylinder !== undefined)
    .map((x) => ({
      product_code: x.cylinder.id.toString(),
      product_unit_value: 0,
      quantity_volume: x.quantity > 0 ? x.quantity : 1,
    }));

  const obj = {
    request_number_temp: orderTempNumber,
    commercialization_type: 'P',
    request_type: new OrderEnums().enums.types.RECHARGE,
    status_id: new OrderEnums().enums.status.UNDER_ANALYSIS,
    Sap_client: patient.sap_number ?? '',
    cnpj: cnpj,
    cpf: patient.cpf,
    distribution_center: branch.id,
    destination_sys_cod: '0',
    destination_system: 'SAP',
    date_entry: formatDate(new Date()),
    serial_note: '',
    net_total_value: 0,
    total_with_tax: 0,
    sta_date: formatDate(new Date()),
    create_in: moment().utc(true).toISOString(),
    create_by: currentUser.email,
    order_type_desc: new OrderEnums().enums.types.RECHARGE,
    delivery_forecast: formatDate(deliveryDate),
    delivery_window: turns.join('/'),
    num_carga: '0',
    obs: techDetails ?? '',
  };

  const objRecharge = {
    ...obj,
    products: toRecharge,
  };

  const objExchange = {
    ...obj,
    products: toExchange,
    exchange: true,
  };

  const objCollect = {
    ...obj,
    products: toCollect,
    hidden: true,
  };

  // Setting responsible operator
  const currentUserJSON = UserHelper.getCurrentUser();
  if (currentUserJSON) {
    if (
      currentUserJSON.isWhiteMartins === '1' ||
      currentUserJSON.isAdmin === '1'
    ) {
      objRecharge.operator_cognito_id = currentUserJSON.username;
      objCollect.operator_cognito_id = currentUserJSON.username;
      objExchange.operator_cognito_id = currentUserJSON.username;
    }
  }

  const orderNumbers = [];
  try {
    const data = [];

    if (objRecharge.products.length > 0) {
      orderNumbers.push(objRecharge.request_number_temp);
      data.push(objRecharge);
    }
    if (objExchange.products.length > 0) {
      orderNumbers.push(objExchange.request_number_temp);
      data.push(objExchange);
    }
    if (objCollect.products.length > 0) {
      orderNumbers.push(objCollect.request_number_temp);
      data.push(objCollect);
    }
    //const resolvedProms = await Promise.all(proms);
    const result = await doPost('/requests', data);
    if (result.message !== 'Request created') {
      return 'erro interno';
    }

    return orderNumbers[0];
  } catch (e) {
    return e.toString();
  }
};
