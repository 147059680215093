import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Toggle from 'react-toggle';
import { PrimaryTitleGray } from '../../base/colors';
import { Spinner } from '../../base/Spinners';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import { WideLayout } from '../../components/layout/wideLayout';
import { usePortalStore } from '../../store';
import { useRechargeStore } from '../../stores/rechargeStore';
import { AutoCompleteRechargePatients } from './autocomplete';
import { ListPatientsRecharge } from './listPatientsRechage';

const MainPanels = () => {
  const reset = useRechargeStore((state) => state.reset);
  const fetchPatients = useRechargeStore((state) => state.fetchPatients);
  const setOnlyWithCylinder = useRechargeStore(
    (state) => state.setOnlyWithCylinder
  );
  const onlyWithCylinders = useRechargeStore(
    (state) => state.onlyWithCylinders
  );
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const keepState = searchParams.get('keepState');
    if (!keepState) {
      reset();
    }
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchPatients(null, onlyWithCylinders);
      setIsLoading(false);
    })();
  }, [onlyWithCylinders]);

  const actionFilter = () => {
    return (
      <>
        <span
          style={{
            fontSize: '14px',
            marginRight: '5px',
            color: PrimaryTitleGray,
          }}
        >
          Mostrar apenas pacientes com cilindros
        </span>
        <Toggle
          checked={onlyWithCylinders}
          icons={false}
          onChange={(e) => setOnlyWithCylinder(e.target.checked)}
        />
      </>
    );
  };

  return (
    <div style={{ margin: '15px' }}>
      <div
        className="recharge-title"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h3 style={{ color: PrimaryTitleGray }}>Recarga</h3>
        <div
          className="recharge-input"
          style={{ marginBottom: '15px', width: '50%' }}
        >
          <AutoCompleteRechargePatients></AutoCompleteRechargePatients>
        </div>
      </div>
      <TitledPanel
        title="Pacientes"
        outlet={
          <>
            {isLoading ? (
              <div
                style={{
                  width: '100%',
                  height: '50vh',
                  display: 'inline-grid',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Spinner></Spinner>
              </div>
            ) : (
              <ListPatientsRecharge></ListPatientsRecharge>
            )}
          </>
        }
        action={actionFilter()}
      ></TitledPanel>
    </div>
  );
};

export const RechargeSelectPatientsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  return (
    <WideLayout
      outlet={isTabletOrMobile ? MainPanels() : MainPanels()}
    ></WideLayout>
  );
};
