import {
  Box,
  Menu,
  Tabs,
  Tab,
  IconButton,
  SvgIcon,
  List,
  Badge,
} from '@mui/material';
import React from 'react';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { Notification } from '../../../types/Notifications';
import { NotificationsDataSource } from '../../../datasources/NotificationsDataSource';
import { NotificationItem } from './NotificationItem';
import { UserHelper } from '../../../helpers/UserHelper';

export const Notifications = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentTab, setCurrentTab] = React.useState('1');
  const [notifications, setNotifications] = React.useState<Notification[]>();
  const [read, setRead] = React.useState<boolean>(false);
  const [badgeCount, setBadgeCount] = React.useState<number>(0);
  const [updateBadge, setUpdateBadge] = React.useState<boolean>(false);
  const toggleUpdateBadge = () => setUpdateBadge(!updateBadge);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    toggleUpdateBadge();
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, value: string) => {
    setCurrentTab(value);

    if (value === '1') {
      setRead(false);
    } else if (value === '2') {
      setRead(true);
    }
  };

  const getAllNotifications = async () => {
    try {
      const currentUser = UserHelper.getCurrentUser();

      if (currentUser) {
        const resp = (await new NotificationsDataSource().getByOperator(
          currentUser.username,
          read
        )) as Array<Notification>;

        setNotifications(resp);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getBadgeCount = async () => {
    try {
      const currentUser = UserHelper.getCurrentUser();

      if (currentUser) {
        const response = await new NotificationsDataSource().getBadgeCount(
          currentUser.username
        );

        if (response !== null) setBadgeCount(response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    getAllNotifications();
    getBadgeCount();
  }, [read, anchorEl, updateBadge]);

  React.useEffect(() => {
    const interval = setInterval(
      () => getBadgeCount().catch(console.error),
      60 * 1000
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <IconButton onClick={handleClick}>
          <Badge badgeContent={badgeCount} max={10} color="warning">
            <SvgIcon>
              <NotificationsIcon htmlColor="white"></NotificationsIcon>
            </SvgIcon>
          </Badge>
        </IconButton>
      </Box>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ width: '500px' }}
      >
        <Box sx={{ minWidth: '300px' }}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              sx={{ width: '100%' }}
              centered
            >
              <Tab label="Não Lidas" value="1"></Tab>
              <Tab label="Lidas" value="2"></Tab>
            </Tabs>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '400px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            <List>
              {notifications ? (
                notifications.map((notification, idx) => (
                  <NotificationItem
                    key={idx}
                    notification={notification}
                    handleCloseMenuList={handleClose}
                  ></NotificationItem>
                ))
              ) : (
                <></>
              )}
            </List>
          </Box>
        </Box>
      </Menu>
    </>
  );
};
