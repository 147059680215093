import React from 'react';
import { Modal } from '../common/Modal';
import {
  Warning as AttentionIcon,
  Error as AlertIcon,
  Feedback as DoneIcon,
  FmdBad as OperationalPendencyIcon,
  Report as EmergencyIcon,
  Person as PersonIcon,
  Description as DescriptionIcon,
  AccessTimeFilled as TimeIcon,
} from '@mui/icons-material';
import { Notification } from '../../types/Notifications';
import { Button, SvgIcon, Typography } from '@mui/material';
import { Header, Title, Body, BodyInfoRow } from './style';
import { TextInput } from '../common/TextInput';
import { NotificationsDataSource } from '../../datasources/NotificationsDataSource';
import { UserHelper } from '../../helpers/UserHelper';
import { CurrentUser } from '../../types/User';
import { AlarmEnum } from '../../enums/AlarmEnum';

type NotificationProps = {
  open: boolean;
  handleClose: () => void;
  notification: Notification;
  handleCloseMenuList: () => void;
};

const alarmEnum = AlarmEnum.enums.color;

export const NotificationModal: React.FC<NotificationProps> = ({
  open,
  handleClose,
  notification,
  handleCloseMenuList,
}) => {
  const [obs, setObs] = React.useState<string>('');
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | undefined>(
    UserHelper.getCurrentUser()
  );

  React.useEffect(() => setCurrentUser(UserHelper.getCurrentUser()), []);
  const getHeaderIcon = () => {
    switch (notification.alarm.id) {
      case alarmEnum.YELLOW:
        return AttentionIcon;
      case alarmEnum.RED:
        return AlertIcon;
      case alarmEnum.PURPLE:
        return DoneIcon;
      case alarmEnum.ORANGE:
        return OperationalPendencyIcon;
      case alarmEnum.FLASHING_RED:
        return EmergencyIcon;
      default:
        return AttentionIcon;
    }
  };

  const handleReadNotification = async () => {
    try {
      const notificationsDataSource = new NotificationsDataSource();
      await notificationsDataSource.read(
        notification.id,
        currentUser!.username,
        obs === '' ? undefined : obs
      );
      handleClose();
      handleCloseMenuList();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      sx={{ width: '30%', padding: 0 }}
    >
      <Header
        alarm_color={notification.alarm.color}
        is_flashing={notification.alarm.is_flashing.toString()}
      >
        <SvgIcon component={getHeaderIcon()} />
        <Title variant="h4">{notification.alarm.name}</Title>
      </Header>
      <Body>
        <BodyInfoRow>
          <PersonIcon />
          <Typography variant="body1">
            Paciente: {notification.patient} - SAP: {notification.patient_sap}
          </Typography>
        </BodyInfoRow>
        <BodyInfoRow>
          <DescriptionIcon />
          <Typography variant="body1">
            Pedido: {notification.request_number_temp}
          </Typography>
        </BodyInfoRow>
        <BodyInfoRow>
          <TimeIcon />
          <Typography variant="body1">
            Prazo contrato do CNPJ: Aplicação e Assistência: 4 horas; Recarga: 4
            horas e Recolhimento 4 Horas.
          </Typography>
        </BodyInfoRow>
        {!notification.read && (
          <BodyInfoRow>
            <TextInput
              label="Observação (opcional)..."
              value={obs}
              handleChange={(e) => setObs(e.target.value)}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleReadNotification()}
              sx={{ width: '290px' }}
            >
              CONFIRMAR CIÊNCIA
            </Button>
          </BodyInfoRow>
        )}
      </Body>
    </Modal>
  );
};
