import create from 'zustand';
import { getReport } from '../services/patientsService';
import { formatDate } from '../utils/utils';
import { getSales } from '../services/salesService';
import { PatientDataSource } from '../datasources/PatientDataSource';

const date = new Date();
date.setMonth(new Date().getMonth());

export const useKPIStore = create((set, get) => ({
  selectedCategory: '',
  selectedCPF: null,
  page: 0,
  oxygenConsumed: 0,
  currentRentalValue: 0,
  pageSize: 10,
  selectedDate: { month: date.getMonth(), year: date.getFullYear() },
  rows: 0,
  page: 0,
  pageSize: 10,
  patients: [],
  setSelectedCategory: (selectedCategory) => set({ selectedCategory }),
  setCPF: (cpf) => {
    set({ selectedCPF: cpf });
  },
  setPagination: (page, pageSize) => {
    set({ page: page, pageSize: pageSize });
  },
  fetchPatientsKpi: async () => {
    const startDate = new Date(
      get().selectedDate.year,
      get().selectedDate.month,
      1
    );
    let endDate = new Date(
      get().selectedDate.year,
      get().selectedDate.month,
      1
    );
    endDate.setMonth(get().selectedDate.month + 1);
    endDate.setDate(endDate.getDate() - 1);

    const salesPromise = getSales(
      get().selectedCPF,
      0,
      10000000,
      formatDate(startDate),
      formatDate(endDate)
    );
    const resp = await getReport(
      get().selectedCPF,
      get().page,
      get().pageSize,
      formatDate(startDate),
      formatDate(endDate)
    );
    const promisesData = resp.data.map(async (patient) => {
      patient.equipments = patient.reports;
      patient.statusComplements =
        await new PatientDataSource().getCustomerAdditionalInformation(
          patient.cpf,
          patient.cnpj
        );
      delete patient.reports;
      return patient;
    });
    const sales = await salesPromise;
    resp.data = await Promise.all(promisesData);
    const totalOxygenConsumed = sales.data
      .map((x) => x.vol_total)
      .reduce((acc, x) => acc + x, 0);
    set({
      patients: [...resp.data],
      page: resp.page,
      pageSize: resp.pagesize,
      rows: resp.rows,
      oxygenConsumed: totalOxygenConsumed,
      currentRentalValue: resp.currentRentalValue,
    });
  },

  setSelectedDate: (month, year) => {
    set({ selectedDate: { month, year } });
  },

  fetchPatientsToReport: async () => {
    const startDate = new Date(
      get().selectedDate.year,
      get().selectedDate.month,
      1
    );
    let endDate = new Date(
      get().selectedDate.year,
      get().selectedDate.month,
      1
    );
    endDate.setMonth(get().selectedDate.month + 1);
    endDate.setDate(endDate.getDate() - 1);
    const salesPromise = getSales(
      get().selectedCPF,
      0,
      10000000,
      formatDate(startDate),
      formatDate(endDate)
    );
    const p = await getReport(
      get().selectedCPF,
      0,
      10000000,
      formatDate(startDate),
      formatDate(endDate)
    );
    const rows = p.rows;
    const pageSize = p.pagesize;
    const totalMonth = endDate.getDate() - 1;
    const totalPages = parseInt(rows / pageSize) + 1;
    const sales = await salesPromise;
    const totalOxygenConsumed = sales.data
      .map((x) => x.vol_total)
      .reduce((acc, x) => acc + x, 0);
    const patientsList = [];
    p.data.forEach((patient) => {
      patient.reports.forEach((report) => {
        const reportData = { ...patient, ...report };
        delete reportData.reports;
        patientsList.push(reportData);
      });
    });
    if (rows < pageSize) {
      return {
        oxygenConsumed: totalOxygenConsumed,
        currentRentalValue: p.currentRentalValue,
        currentLeasing: parseFloat(
          patientsList
            .map((x) =>
              !isNaN(parseFloat(x.monthly_lease))
                ? parseFloat(x.monthly_lease)
                : 0
            )
            .reduce((x, acc) => x + acc, 0)
            .toFixed(2)
        ),
        patients: patientsList,
        totalMonth: totalMonth,
        year: get().selectedDate.year,
        month: get().selectedDate.month + 1,
      };
    }
    const results = await Promise.all(
      Array.from({ length: totalPages - 1 })
        .map((x, i) => i + 1)
        .map((page) =>
          getReport(
            get().selectedCPF,
            page,
            pageSize,
            formatDate(startDate),
            formatDate(endDate)
          )
        )
    );
    for (const r of results) {
      p.data = p.data.concat(r.data);
    }
    return {
      oxygenConsumed: totalOxygenConsumed,
      currentRentalValue: p.currentRentalValue,
      currentLeasing: parseFloat(
        patientsList
          .map((x) =>
            !isNaN(parseFloat(x.monthly_lease))
              ? parseFloat(x.monthly_lease)
              : 0
          )
          .reduce((x, acc) => x + acc, 0)
          .toFixed(2)
      ),
      patients: patientsList,
      totalMonth: totalMonth,
      year: get().selectedDate.year,
      month: get().selectedDate.month + 1,
    };
  },
}));
