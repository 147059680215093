import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { useMediaQuery } from 'react-responsive';
import { PrimaryTitleGray } from '../../base/colors';
import {
  PrimaryButton,
  SecondaryButton,
  TextAreaInput,
} from '../../base/Inputs';
import { ImageSlider } from '../../components/image/slider';
import { saveCollectionOrder } from '../../services/collectionService';
import { getProductsImages } from '../../services/productService';
import { usePortalStore } from '../../store';
import { useCollectionStore } from '../../stores/collectionStore';
import { DefaultFinishOrder } from '../../usecase/orders/defaultFinishOrder';
import { getBase64Img } from '../../utils/utils';
import { MdLocalHotel } from 'react-icons/md';
import { GiHandTruck } from 'react-icons/gi';
import { groupBySegment } from './collection';

export const FinishCollectionOrder = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const currentUser = usePortalStore((state) => state.currentUser);
  const configuredCollections = useCollectionStore(
    (state) => state.configuredCollections
  );
  const selectedPatient = useCollectionStore((state) => state.selectedPatient);
  const getKeptItems = useCollectionStore((state) => state.getKeptItems);
  const [image, setImage] = useState([]);
  const [techDetails, setTechDetails] = useState('');
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );

  useEffect(() => {
    (async () => {
      const img = await getProductsImages(
        configuredCollections
          .map((x) => [x.id, ...(x.accessories ?? []).map((y) => y.id)])
          .flat()
      );
      setImage([...img]);
    })();
  }, []);
  const finalCollect = {};
  configuredCollections.forEach((parent) => {
    if (!finalCollect[parent.id]) {
      finalCollect[parent.id] = {
        product_code: parent.id,
        product_unit_value: 0,
        quantity_volume: parseInt(parent.qty),
      };
      parent.accessories.forEach((acc) => {
        if (!finalCollect[acc.id]) {
          finalCollect[acc.id] = {
            product_code: acc.id.toString(),
            product_unit_value: 0,
            quantity_volume: parseInt(acc.qty),
          };
        } else {
          finalCollect[acc.id.toString()].quantity_volume += 1;
        }
      });
    }
  });
  const finishOrder = async (deliveryDate, deliveryTurn) => {
    const orderNumber = await saveCollectionOrder(
      currentUser,
      selectedPatient.cnpj,
      selectedPatient,
      finalCollect,
      deliveryDate,
      techDetails,
      deliveryTurn
    );
    if (orderNumber === null) {
      setCurrentAlertError(
        `Filial não encontrada para a cidade ${selectedPatient.city}`
      );
    }
    return orderNumber;
  };
  const topTile = () => {
    return (
      <div
        style={{
          display: isTabletOrMobile ? 'block' : 'inline-flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ flex: '1', minWidth: '400px', maxWidth: '400px' }}>
          <ImageSlider images={image.map((x) => getBase64Img(x))}></ImageSlider>
        </div>
        <div style={{ marginLeft: '15px', width: '100%' }}>
          {confirmChanges()}
        </div>
      </div>
    );
  };
  const confirmChanges = () => {
    return (
      <div style={{ position: 'relative', fontSize: '14px' }}>
        {/* <div>
                    <div style={{display:"flex",alignItems:"center",borderBottom: "solid 1px #ccc", marginBottom: "5px"}}>
                        <MdLocalHotel style={{fontSize:"25px",marginRight:"9px",color:"#318300"}}></MdLocalHotel> 
                        <div style={{fontSize: "16px"}}>Itens que serão mantidos</div>
                    </div>
                    <div>
                        <ul style={{margin:"0", padding:"0"}}>
                            {Object.values(groupBySegment(getKeptItems().filter(x => x.collect === false))).map((ss,idx)=>
                            <li style={{listStyle: "none"}} key={idx}>
                                <p>{ss.segment}</p>
                                {ss.data.map((acc,j)=><div style={{listStyle: "none",padding: "6px 15px",background: "#efefef",borderRadius: "10px",marginBottom: "5px",marginLeft: "35px"}} key={j}>{acc.name}</div>)}
                            </li>)}
                        </ul>
                    </div>
                </div> */}

        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 'solid 1px #ccc',
              marginBottom: '5px',
              marginTop: '10px',
            }}
          >
            <GiHandTruck
              style={{ fontSize: '30px', marginRight: '9px', color: '#b34141' }}
            ></GiHandTruck>
            <div style={{ fontSize: '16px' }}>Itens que serão recolhidos</div>
          </div>
          <div>
            <ul style={{ margin: '0', padding: '0' }}>
              {Object.values(
                groupBySegment(getKeptItems().filter((x) => x.collect === true))
              ).map((ss, idx) => (
                <li style={{ listStyle: 'none' }} key={idx}>
                  <p>{ss.segment}</p>
                  {ss.data.map((acc, j) => (
                    <div
                      style={{
                        listStyle: 'none',
                        padding: '6px 15px',
                        background: '#efefef',
                        borderRadius: '10px',
                        marginBottom: '5px',
                        marginLeft: '35px',
                      }}
                      key={j}
                    >
                      {acc.qty}x {acc.name}
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  const bottomTile = () => {
    return (
      <div style={{ width: '100%' }}>
        <h4
          style={{
            color: PrimaryTitleGray,
            margin: '0px',
            marginBottom: '10px',
          }}
        >
          Informações adicionais
        </h4>
        <TextAreaInput
          onChange={(e) => setTechDetails(e.target.value)}
          style={{ minWidth: '100%', minHeight: '120px', margin: '0px' }}
        ></TextAreaInput>
      </div>
    );
  };
  return (
    <DefaultFinishOrder
      topTileRender={topTile}
      bottomTileRender={bottomTile}
      selectedPatient={selectedPatient}
      onFinish={finishOrder}
      orderType={'Recolhimento'}
      fastDeliveryLabel={'Recolhimento imediato'}
      scheduleDeliveryLabel={'Recolhimento agendado'}
    ></DefaultFinishOrder>
  );
};
