import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type HeaderStyleProps = {
  alarm_color: string;
  is_flashing: string;
};

export const Header = styled(Box)<HeaderStyleProps>(
  ({ alarm_color, is_flashing, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '5px',
    background: alarm_color,
    padding: '20px',

    '& svg': {
      color: theme.colors.text.primary,
    },

    animation: is_flashing === 'true' ? 'flasher 1.5s infinite' : 'unset',

    '@keyframes flasher': {
      '0%': {
        backgroundColor: alarm_color,
      },
      '50%': {
        backgroundColor: 'white',
      },
      '100%': {
        backgroundColor: alarm_color,
      },
    },
  })
);

export const Body = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px',

  '& svg': {
    color: theme.colors.text.primary,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: '700',
  color: theme.colors.text.primary,
}));

export const BodyInfoRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  color: theme.colors.text.secondary,
}));
