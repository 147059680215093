import React from 'react';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { MultipleSelectItems } from '../components/common/MultipleSelect';
import { OrdersDataSource } from '../datasources/OrdersDataSource';
import { SelectChangeEvent } from '@mui/material';
import { ComboBoxItems } from '../components/common/Combobox';
import { Operator } from '../types/User';
import { useSnackbar } from '../hooks/useSnackbar';
import { OrderToUpdate } from '../types/Order';
import { UserHelper } from '../helpers/UserHelper';
import { OrderEnums } from '../enums/OrderEnums';

type OrdersHookProps = {
  order_id?: number;
  handleClose: () => void;
  refreshOrdersTable: () => void;
};

type OrderSelectItems = {
  status: ComboBoxItems[];
  statusComplements: ComboBoxItems[];
  deliveryOperations: MultipleSelectItems[];
  serviceEvents: MultipleSelectItems[];
  retreatReasons: MultipleSelectItems[];
};

type VisibleClassificationsValues = {
  statusComplements: Array<string>;
  deliveryOperations: Array<string>;
  serviceEvents: Array<string>;
  retreatReasons: Array<string>;
};

type CurrentOrder = {
  request_type: string;
  request_number_temp: string;
  request_number: Array<string>; // SAP numbers
  status_id: string;
  status_complement_id: string | undefined;
  delivery_forecast: Moment | undefined;
  delivery_window: Array<{ label: string; value: string; checked: boolean }>;
  responsible_operator: Operator | undefined;
  delivery_operations: Array<string>;
  service_events: Array<string>;
  retreat_reasons: Array<string>;
};

/**
 * Hook that handles Orders requests.
 * @returns hook methods.
 */
export const useOrders = ({
  order_id,
  handleClose,
  refreshOrdersTable,
}: OrdersHookProps) => {
  const snackbar = useSnackbar();

  const orderEnums = new OrderEnums();
  const [order, setOrder] = React.useState<CurrentOrder>({
    request_type: '',
    request_number_temp: '',
    request_number: [''],
    status_id: '',
    status_complement_id: '',
    delivery_forecast: moment(),
    delivery_window: [
      { label: 'Manhã', value: 'manha', checked: false },
      { label: 'Tarde', value: 'tarde', checked: false },
      { label: 'Noite', value: 'noite', checked: false },
    ],
    responsible_operator: undefined,
    delivery_operations: [],
    service_events: [],
    retreat_reasons: [],
  });

  const [errorValidationMessage, setErrorValidationMessage] = React.useState({
    status: undefined as string | undefined,
    statusComplement: undefined as string | undefined,
    retreatReason: undefined as string | undefined,
  });

  const [allFetchedSelectItems, setAllFetchedSelectItems] =
    React.useState<OrderSelectItems>({
      status: [] as ComboBoxItems[],
      statusComplements: [] as ComboBoxItems[],
      deliveryOperations: [] as MultipleSelectItems[],
      serviceEvents: [] as MultipleSelectItems[],
      retreatReasons: [] as MultipleSelectItems[],
    });

  const [visibleSelectItems, setVisibleSelectItems] =
    React.useState<OrderSelectItems>({
      status: [] as ComboBoxItems[],
      statusComplements: [] as ComboBoxItems[],
      deliveryOperations: [] as MultipleSelectItems[],
      serviceEvents: [] as MultipleSelectItems[],
      retreatReasons: [] as MultipleSelectItems[],
    });

  const handleChangeStatus = async (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;

    await getVisibleItems(allFetchedSelectItems, value, order.request_type);

    if (orderEnums.status.isConfirmed(value))
      handleClickTakeResponsibility({ ...order, status_id: value });
    else setOrder({ ...order, status_id: value });
  };

  const handleChangeStatusComplement = (event: SelectChangeEvent<unknown>) => {
    setOrder({ ...order, status_complement_id: event.target.value as string });
    setErrorValidationMessage({
      ...errorValidationMessage,
      statusComplement: undefined,
    });
  };

  const handleChangeDeliveryOperation = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    setOrder({
      ...order,
      delivery_operations:
        typeof value === 'string' ? value.split(',') : (value as string[]),
    });
  };

  const handleChangeServiceEvent = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    setOrder({
      ...order,
      service_events:
        typeof value === 'string' ? value.split(',') : (value as string[]),
    });
  };

  const handleChangeRetreatReason = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    setOrder({
      ...order,
      retreat_reasons:
        typeof value === 'string' ? value.split(',') : (value as string[]),
    });
    setErrorValidationMessage({
      ...errorValidationMessage,
      retreatReason: undefined,
    });
  };

  const handleCheckDeliveryWindow = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOrder({
      ...order,
      delivery_window: order.delivery_window.map((item) => {
        if (item.value === event.target.value) {
          item.checked = event.target.checked;
        }

        return item;
      }),
    });
  };

  const fetchCurrentOrder = async (order_id?: number) => {
    try {
      if (order_id) {
        const currentOrder = await new OrdersDataSource().getOrderById(
          order_id
        );

        const newOrder: CurrentOrder = {
          request_type: currentOrder.request_type,
          request_number_temp: currentOrder.request_number_temp,
          request_number: currentOrder.request_number?.split(',') ?? [''],
          status_id: currentOrder.status.id.toString(), //_.snakeCase(currentOrder.status_id.toString()),
          status_complement_id: currentOrder.status_complement
            ? currentOrder.status_complement.id.toString()
            : '',
          delivery_forecast: moment(currentOrder.delivery_forecast),
          delivery_window: order.delivery_window.map((item) => {
            if (
              currentOrder.delivery_window
                .toLocaleLowerCase()
                .includes(item.value)
            ) {
              item.checked = true;
            }

            return item;
          }),
          responsible_operator: currentOrder.responsible_operator,
          delivery_operations:
            currentOrder.delivery_operations.map((item) =>
              item.id.toString()
            ) ?? [],
          service_events:
            currentOrder.service_events.map((item) => item.id.toString()) ?? [],
          retreat_reasons:
            currentOrder.retreat_reasons.map((item) => item.id.toString()) ??
            [],
        };
        const allSelectItems = await getMultiSelectData();

        await getVisibleItems(
          allSelectItems,
          newOrder.status_id,
          newOrder.request_type
        );
        setOrder(newOrder);
      }
    } catch (e) {
      console.log(e);
    } finally {
      //setIsLoading(false);
    }
  };

  const getMultiSelectData = async () => {
    const ordersDataSource = new OrdersDataSource();

    const [
      deliveryOperations,
      serviceEvents,
      retreatReasons,
      statusComplements,
      status,
    ] = await Promise.all([
      ordersDataSource.getDeliveryOperations(),
      ordersDataSource.getServiceEvents(),
      ordersDataSource.getRetreatReasons(),
      ordersDataSource.getStatusComplements(),
      ordersDataSource.getStatus(),
    ]);

    const newData: OrderSelectItems = {
      status: status,
      statusComplements: statusComplements,
      deliveryOperations: deliveryOperations,
      serviceEvents: serviceEvents,
      retreatReasons: retreatReasons,
    };

    setAllFetchedSelectItems(newData);

    return newData;
  };

  const getVisibleItems = async (
    items: OrderSelectItems,
    status_id: string,
    orderType: string
  ) => {
    const visibleClassifications = await getVisibleClassificationsValues(
      status_id,
      orderType
    );

    const visibleStatusComplements = items.statusComplements.filter((x) => {
      return visibleClassifications.statusComplements.includes(x.value);
    });

    const deliveryOperations = items.deliveryOperations.filter((x) => {
      return visibleClassifications.deliveryOperations.includes(x.value);
    });

    const serviceEvents = items.serviceEvents.filter((x) => {
      return visibleClassifications.serviceEvents.includes(x.value);
    });

    const retreatReasons = items.retreatReasons.filter((x) => {
      return visibleClassifications.retreatReasons.includes(x.value);
    });

    const newItems: OrderSelectItems = {
      deliveryOperations: deliveryOperations,
      serviceEvents: serviceEvents,
      retreatReasons: retreatReasons,
      status: items.status,
      statusComplements: visibleStatusComplements,
    };

    setVisibleSelectItems(newItems);

    return newItems;
  };

  const handleClickTakeResponsibility = (currentOrder?: CurrentOrder) => {
    const currentUserJSON = UserHelper.getCurrentUser();

    if (currentUserJSON) {
      const currentUser: Operator = {
        cognito_id: currentUserJSON.username,
        name: currentUserJSON.name,
        email: currentUserJSON.email,
        cnpj: currentUserJSON.cnpj,
        company: currentUserJSON.companyName,
      };

      setOrder({
        ...(currentOrder ?? order),
        responsible_operator: currentUser,
      });
    }
  };

  const handleSave = async () => {
    try {
      if (order_id) {
        await validateForm();
        const newOrder = await validateClassifications();

        const ordersDataSource = new OrdersDataSource();

        const orderToSave: OrderToUpdate = {
          id: order_id,
          request_number: newOrder.request_number.join(','),
          status_id: parseInt(newOrder.status_id),
          status_complement_id: _.isEmpty(newOrder.status_complement_id)
            ? undefined
            : parseInt(newOrder.status_complement_id!),
          delivery_forecast: newOrder.delivery_forecast?.toISOString() ?? '', // TODO: Change to undefined if it is empty
          delivery_window: newOrder.delivery_window
            .filter((item) => item.checked)
            .map((item) => item.value)
            .join('/'),
          operator_cognito_id: newOrder.responsible_operator?.cognito_id,

          delivery_operations: newOrder.delivery_operations,
          service_events: newOrder.service_events,
          retreat_reasons: newOrder.retreat_reasons,
        };

        await ordersDataSource.update(orderToSave);
        setOrder(newOrder);
        handleClose();
        refreshOrdersTable();
        snackbar.show('Salvo com sucesso!', 'success');
      }
    } catch (e: any) {
      snackbar.show(e.message, 'error');
    }
  };

  const getVisibleClassificationsValues = async (
    status_id: string,
    orderType: string
  ) => {
    const statusComplements: Array<string> = [];
    let deliveryOperationsValues: Array<string> = [];
    let serviceEventsValues: Array<string> = [];
    let retreatReasonsValues: Array<string> = [];

    if (orderEnums.status.isConcluded(status_id)) {
      if (
        orderEnums.types.isImplementation(orderType) ||
        orderEnums.types.isTechnicalAssistance(orderType) ||
        orderEnums.types.isRecharge(orderType)
      ) {
        statusComplements.push(
          orderEnums.enums.statusComplement.DELIVERED_WITH_ORIENTATIONS.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.PRODUCT_CHANGED.toString()
        );
      }
      if (orderEnums.types.isTechnicalAssistance(orderType)) {
        statusComplements.push(
          orderEnums.enums.statusComplement.CHANGED.toString()
        );
      }
    } else if (orderEnums.status.isUnsuccessful(status_id)) {
      if (
        orderEnums.types.isImplementation(orderType) ||
        orderEnums.types.isTechnicalAssistance(orderType) ||
        orderEnums.types.isRecharge(orderType) ||
        orderEnums.types.isRetreat(orderType)
      ) {
        statusComplements.push(
          orderEnums.enums.statusComplement.SECURITY_PROBLEMS.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.CANCELLED.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.NOBODY_AT_HOME.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.ROAD_CLOSED.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.NOT_AUTHORIZED.toString()
        );
      }
      if (
        orderEnums.types.isRetreat(orderType) ||
        orderEnums.types.isTechnicalAssistance(orderType) ||
        orderEnums.types.isRecharge(orderType)
      ) {
        statusComplements.push(
          orderEnums.enums.statusComplement.EXTERNAL_EQUIPMENTS.toString()
        );
      }
      if (orderEnums.types.isImplementation(orderType)) {
        statusComplements.push(
          orderEnums.enums.statusComplement.INCORRECT_ADDRESS.toString()
        );
        statusComplements.push(
          orderEnums.enums.statusComplement.PRODUCT_UNAVAILABLE.toString()
        );
      }
    } else if (orderEnums.status.isConfirmed(status_id)) {
      if (
        orderEnums.types.isImplementation(orderType) ||
        orderEnums.types.isRetreat(orderType) ||
        orderEnums.types.isTechnicalAssistance(orderType) ||
        orderEnums.types.isRecharge(orderType)
      ) {
        statusComplements.push(
          orderEnums.enums.statusComplement.PARTIALLY_DONE.toString()
        );
      }
    }

    if (
      orderEnums.status.isConfirmed(status_id) ||
      orderEnums.status.isUnsuccessful(status_id)
    ) {
      deliveryOperationsValues = [
        orderEnums.enums.deliveryOperations.OPERATIONAL_PENDENCY.toString(),
        orderEnums.enums.deliveryOperations.EMERGENCY.toString(),
      ];
    } else if (orderEnums.status.isConcluded(status_id)) {
      deliveryOperationsValues = [
        orderEnums.enums.deliveryOperations.ABSENCE_OR_PARTIAL_RECEIPT.toString(),
      ];
    }

    if (
      orderEnums.status.isConfirmed(status_id) ||
      orderEnums.status.isConcluded(status_id)
    ) {
      serviceEventsValues = [
        orderEnums.enums.serviceEvents.UNPRODUCTIVE_VISIT.toString(),
        orderEnums.enums.serviceEvents.DELIVERY_WITHOUT_PATIENT_REQUEST.toString(),
        orderEnums.enums.serviceEvents.DIVERGENCE_IN_ORDER_EXECUTION.toString(),
        orderEnums.enums.serviceEvents.PGK_OPERATION_FAILURE.toString(),
        orderEnums.enums.serviceEvents.HOMECARE_OPERATION_FAILURE.toString(),
      ];
    }

    if (
      orderEnums.status.isConcluded(status_id) &&
      orderEnums.types.isRetreat(orderType)
    ) {
      retreatReasonsValues = [
        orderEnums.enums.retreatReasons.END_OF_USE.toString(),
        orderEnums.enums.retreatReasons.HOSPITALIZATION.toString(),
        orderEnums.enums.retreatReasons.PAYMENT_DUE_TO_DEATH.toString(),
        orderEnums.enums.retreatReasons.SUPPLIER_CHANGE.toString(),
      ];
    }

    const newData: VisibleClassificationsValues = {
      statusComplements: statusComplements.sort(),
      deliveryOperations: deliveryOperationsValues.sort(),
      serviceEvents: serviceEventsValues.sort(),
      retreatReasons: retreatReasonsValues.sort(),
    };

    return newData;
  };

  const validateClassifications = async () => {
    const visibleClassifications = await getVisibleClassificationsValues(
      order.status_id,
      order.request_type
    );

    const statusComplementsResult =
      visibleClassifications.statusComplements.includes(
        order.status_complement_id as string
      )
        ? order.status_complement_id
        : undefined;

    const deliveryOperationsResult = order.delivery_operations.filter(
      (value: string) => {
        return visibleClassifications.deliveryOperations.includes(value);
      }
    );

    const serviceEventsResult = order.service_events.filter((value: string) => {
      return visibleClassifications.serviceEvents.includes(value);
    });

    const retreatReasonsResult = order.retreat_reasons.filter(
      (value: string) => {
        return visibleClassifications.retreatReasons.includes(value);
      }
    );

    const newOrder = {
      ...order,
      status_complement_id: statusComplementsResult,
      delivery_operations: deliveryOperationsResult,
      service_events: serviceEventsResult,
      retreat_reasons: retreatReasonsResult,
    };

    return newOrder;
  };

  const validateForm = async () => {
    if (
      orderEnums.status.isUnsuccessful(order.status_id) &&
      _.isEmpty(order.status_complement_id)
    ) {
      const message =
        'O Complemento de status do pedido é obrigatório quando insucesso.';
      setErrorValidationMessage({
        ...errorValidationMessage,
        statusComplement: message,
      });
      throw new Error(message);
    }

    if (
      orderEnums.types.isRetreat(order.request_type) &&
      orderEnums.status.isConcluded(order.status_id) &&
      _.isEmpty(order.retreat_reasons)
    ) {
      const message = 'O Motivo do Recolhimento é obrigatório.';
      setErrorValidationMessage({
        ...errorValidationMessage,
        retreatReason: message,
      });
      throw new Error(message);
    }
  };

  return {
    // States
    order,
    setOrder,
    errorValidationMessage,
    visibleSelectItems,
    handleChangeStatus,
    handleChangeStatusComplement,
    handleChangeDeliveryOperation,
    handleChangeServiceEvent,
    handleChangeRetreatReason,
    handleCheckDeliveryWindow,

    // Methods
    fetchCurrentOrder,
    getMultiSelectData,
    handleSave,
    handleClickTakeResponsibility,
  };
};
