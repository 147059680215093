import { Api } from '../api';
import { PatientStatusComplement } from '../types/Patient';
import { MultipleSelectItems } from '../components/common/MultipleSelect';

export class PatientDataSource {
  async getAdditionalInformation() {
    const result = (await new Api().fetch(
      '/customer/additional-information'
    )) as Array<PatientStatusComplement>;
    const data = result.map((status) => {
      const multipleSelectStatus: MultipleSelectItems = {
        label: status.name,
        value: status.id.toString(),
      };
      return multipleSelectStatus;
    });

    return data;
  }

  async getCustomerAdditionalInformation(cpf: string, cnpj: string) {
    let newCpf = null;
    let newCnpj = null;

    if (cpf && cnpj) {
      newCpf = cpf.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
      newCnpj = cnpj
        .replaceAll('.', '')
        .replaceAll('/', '')
        .replaceAll('-', '');

      const result = (await new Api().fetch(
        `/customer/additional-information/cpf/${newCpf}/cnpj/${newCnpj}`
      )) as Array<{ id: number; name: string }>;
      return result;
    }
  }
}
