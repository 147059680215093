import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Green3 } from '../../base/colors';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { PrimaryButton } from '../../base/Inputs';
import { GreenLabel, YellowLabel, RedLabel } from '../../base/labels';
import { Table } from '../../base/table';
import { TitledPanel } from '../../components/dashboards/titledPanel/titledPanel';
import { ImageSliderPop } from '../../components/popup/imageSlider';
import { useMainStore } from '../../stores/mainStore';
import { formatCPF, formatDate } from '../../utils/utils';
import { getProofOfDelivery } from '../../services/ordersService';
import { usePortalStore } from '../../store';
import { PatientName } from '../../components/patients/PatientName';
import { OrderEnums } from '../../enums/OrderEnums';

const ActionFilters = () => {
  const navigate = useNavigate();
  return (
    <PrimaryButton
      style={{
        width: '150px',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '15px',
        height: 'auto',
      }}
      onClick={() => navigate('/order/newOrder')}
    >
      Novo pedido
    </PrimaryButton>
  );
};

const Outlet = ({ onOpenActivity }) => {
  const [selectedFilter, setSelectedFilter] = useState({});
  const currentUser = usePortalStore((state) => state.currentUser);

  const filters = [
    'Implantação',
    'Assistência técnica',
    'Recarga',
    'Recolhimento',
    'Venda',
    'Serviço',
  ];
  const header = [
    'Nº Pedido',
    'Tipo',
    'CPF',
    'Nome',
    'Data de inclusão',
    'Status',
    'Atividade',
  ];
  const fields = [
    'numeroPedido',
    'type',
    'cpf',
    'name',
    'createdAt',
    'status',
    'activity',
  ];
  const recentOrders = useMainStore((state) => state.recentOrders).sort(
    (a, b) => b.createdAt - a.createdAt
  );
  const fetchRecentOrders = useMainStore((state) => state.fetchRecentOrders);
  useEffect(() => {
    const updateOrders = async () => {
      const filters = Object.keys(selectedFilter)
        .filter((x) => selectedFilter[x])
        .join(',');
      await fetchRecentOrders(filters);
    };
    updateOrders().catch(console.error);
  }, [selectedFilter]);

  const orderEnums = new OrderEnums();

  return (
    <div style={{ display: 'block', position: 'relative' }}>
      <div className="filter-checks">
        {filters.map((x, idx) => (
          <div key={idx} className="filters-options">
            <input
              type="checkbox"
              value={x}
              name={x}
              checked={selectedFilter[x] === true}
              onChange={(e) =>
                setSelectedFilter({ ...selectedFilter, [x]: e.target.checked })
              }
            ></input>
            <label>{x}</label>
          </div>
        ))}
      </div>
      <div style={{ height: '300px', width: '100%', overflow: 'auto' }}>
        <Table
          header={header}
          fields={fields}
          data={recentOrders.map((order) => {
            const columns = [];
            fields.forEach((f) => {
              if (f === 'status' && order.status) {
                columns.push(
                  orderEnums.status.isConcluded(order.status.id.toString()) ||
                    orderEnums.status.isConfirmed(
                      order.status.id.toString()
                    ) ? (
                    <GreenLabel
                      style={{
                        textTransform: 'capitalize',
                        textAlign: 'center',
                      }}
                    >
                      {order.status.name}
                    </GreenLabel>
                  ) : orderEnums.status.isUnsuccessful(
                      order.status.id.toString()
                    ) ? (
                    <RedLabel
                      style={{
                        textTransform: 'capitalize',
                        textAlign: 'center',
                      }}
                    >
                      {order.status.name}
                    </RedLabel>
                  ) : (
                    <YellowLabel
                      style={{
                        textTransform: 'capitalize',
                        textAlign: 'center',
                      }}
                    >
                      {order.status.name}
                    </YellowLabel>
                  )
                );
              } else if (f === 'cpf' && order[f]) {
                columns.push(formatCPF(order[f]));
              } else if (f === 'createdAt' && order[f]) {
                columns.push(formatDate(order[f], 'DD/MM/YYYY HH:mm:ss'));
              } else if (
                f === 'activity' &&
                (sessionStorage.getItem('forceShowActivity') === 'true' ||
                  orderEnums.status.isConcluded(order.status.id.toString()))
              ) {
                columns.push(
                  <div>
                    Comprovante:{' '}
                    <HiOutlineDocumentReport
                      style={{ fontSize: '18px', cursor: 'pointer' }}
                      onClick={() => onOpenActivity(order)}
                    ></HiOutlineDocumentReport>
                  </div>
                );
              } else if (f === 'name') {
                columns.push(
                  <PatientName
                    name={order.name}
                    additionalInformation={order.customerAdditionalInformation?.map(
                      (i) => {
                        return i.id;
                      }
                    )}
                    canSeeFullContent={
                      currentUser.isWhiteMartins || currentUser.isAdmin
                    }
                  ></PatientName>
                );
              } else {
                columns.push(order[f]);
              }
            });
            return columns;
          })}
        ></Table>
      </div>
      <div style={{ marginTop: '10px', textAlign: 'right' }}>
        <Link
          style={{ color: Green3, fontWeight: '700', cursor: 'pointer' }}
          to={'/orders'}
        >
          Ver mais
        </Link>
      </div>
    </div>
  );
};

export const OrdersView = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [openActivity, setOpenActivity] = useState(false);
  const [proofOfDelivery, setProofOfDelivery] = useState([]);
  const setCurrentAlertError = usePortalStore(
    (state) => state.setCurrentAlertError
  );
  const onOpenActivity = async (order) => {
    setOpenActivity(true);
    setSelectedOrder(order);
    const proofOfDelivery = await getProofOfDelivery(order.numeroSAP);
    if (proofOfDelivery.length === 0) {
      setOpenActivity(false);
      setCurrentAlertError('Não foi encontrado nenhum recibo!');
    }
    setProofOfDelivery([...proofOfDelivery]);
  };
  return (
    <>
      {openActivity ? (
        <ImageSliderPop
          closeSlider={() => {
            setOpenActivity(false);
            setProofOfDelivery([]);
          }}
          imagesURL={proofOfDelivery}
        ></ImageSliderPop>
      ) : (
        <></>
      )}
      <TitledPanel
        title="Pedidos recentes"
        action={ActionFilters()}
        outlet={Outlet({ onOpenActivity })}
      ></TitledPanel>
    </>
  );
};
